export const routerRestApi = [
  {
    path: '/rest-api',
    meta: { title: 'Rest Api' },
    component: () => import('./views/rest-api'),
  },
  {
    path: '/rest-api/distributor',
    meta: { title: 'Rest Api Distributor' },
    component: () => import('./views/rest-api/distributor'),
  },
]
